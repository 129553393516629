import { encrypt } from '../utils/crypto'
import axios from './BaseService'

const API_URL = process.env.REACT_APP_API_URL

export async function doLogin(email, password) {
  const loginUrl = `${API_URL}/login`
  const post = { email, password }
  const signature = encrypt(JSON.stringify(post))
  const response = await axios.post(loginUrl, post, { headers: { signature } })
  return response.data
}

export async function validateToken() {
  const validateUrl = `${API_URL}/validateToken`
  const response = await axios.get(validateUrl)
  return response.data
}

export async function doLogout() {
  const logoutUrl = `${API_URL}/logout`
  const response = await axios.post(logoutUrl, {})
  return response.data
}
