import { encrypt } from '../utils/crypto'
import axios from './BaseService'

const ORDERS_URL = `${process.env.REACT_APP_API_URL}/ordersmmn/`

export async function getOrders(token, page, limit, search, status, searchUser, ordenation, orderId, exact) {
  const endPoint = `${ORDERS_URL}list${page ? `?page=${page}` : ''}`
  const post = { status, limit, search, searchUser, ordenation, orderId, exact }
  const signature = encrypt(JSON.stringify(post))
  const response = await axios.post(endPoint, post, { headers: { signature } })
  return response.data
}

export async function payOrder(token, order, type) {
  const endPoint = `${ORDERS_URL}payOrder`
  const post = { order, type }
  const signature = encrypt(JSON.stringify(post))
  const response = await axios.post(endPoint, post, { headers: { signature } })
  return response.data
}
