import React from 'react'
import { Route, BrowserRouter, Redirect } from 'react-router-dom'
import Login from './public/Login/Login'
import Dashboard from './private/Dashboard/Dashboard'
import Users from './private/Users/Users'
import Orders from './private/Orders/Orders'
import Limits from './private/Limits/Limits'

import TelegramChat from './private/Users/TelegramChat'
import Withdrawals from './private/Withdrawals/Withdrawals'
import Calls from './private/Calls/Calls'
import ReadCall from './private/Calls/ReadCall'
import CredDeb from './private/CredDeb/CredDeb'
import Binary from './private/Binary/Binary'
import WithdrawalsFiles from './private/Withdrawals/WithdrawalsFiles'
import Logout from './public/Login/Logout'
import Influencers from './private/Influencers/Influencers'
import Profile from './private/Settings/Profile'
import Raffels from './private/Raffels'
import Prolabore from './private/Prolabore'
import Menu from './components/Menu/Menu'

function Routes() {
  function P({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={() => {
					return sessionStorage.getItem('token') ? <><Menu />{children}</> : <Redirect to="/logout" />
        }}
      />
    )
  }

  return (
    <BrowserRouter>
      <Route path="/" exact>
        <Login />
      </Route>
      <Route path="/logout" exact>
        <Logout />
      </Route>
      <P path="/dashboard"><Dashboard /></P>
      {/* <P path="/settings"><Settings /></P> */}
      <P path="/profile"><Profile /></P>

      <P path="/users"><Users /></P>
      <P path="/orders"><Orders /></P>
      <P path="/raffels"><Raffels /></P>
      <P path="/influencers"><Influencers /></P>
      <P path="/sonosfac"><CredDeb /></P>
      <P path="/sonosprolabore"><Prolabore /></P>
      <P path="/binary"><Binary /></P>
      <P path="/withdrawals"><Withdrawals /></P>
      <P path="/withdrawalsfiles"><WithdrawalsFiles /></P>
      <P path="/calls"><Calls /></P>

      <P path="/readcall"><ReadCall /></P>
      <P path="/limits"><Limits /></P>
      <P path="/telegram-chat"><TelegramChat /></P>
    </BrowserRouter>
  )
}

export default Routes
