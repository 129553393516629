/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react'
import { saveBinaryUser } from '../../../services/UsersService'
import { i18n } from '../../../utils/translates/i18n'

/**
 * props:
 * - data
 * - onSubmit
 */
function UserBinaryModal({ userName, binaries, doSubmit }) {
  const [binary, setBinary] = useState({})

  const btnClose = useRef('')

  function onSubmit(event) {
    if (!!binary.userId)
      saveBinaryUser(binary).then(result => {
        btnClose.current.click()
        if (doSubmit) doSubmit(result)
      })
  }

  function onInputChange(event) {
    const value = event.target.value <= 0 ? 0 : event.target.value
    setBinary(prevState => ({ ...prevState, [event.target.id]: value }))
  }

  useEffect(() => {
    setBinary({
      ...binaries,
    })
  }, [binaries])

  useEffect(() => {
    const modal = document.getElementById('modalBinaryUser')
    modal.addEventListener('hidden.bs.modal', event => {
      setBinary({})
    })
  }, [])

  return (
    <div
      className="modal fade"
      id="modalBinaryUser"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalTitleNotify"
      aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title" id="modalTitleNotify">
              {i18n.t('edit')} {i18n.t('binary')}
            </p>
            <button
              ref={btnClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="close"></button>
          </div>
          <div className="modal-body">
            <div className="h5 fw-bold">{userName}</div>
            <div className="card">
              <div className="card-body">
                {!binary?.userId ? (
                  <div className="alert alert-danger fw-bolder">{i18n.t('no_binary_at_today').toUpperCase()}</div>
                ) : (
                  <>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-4 mb-3">
                          <div className="form-group">
                            <label htmlFor="left">{i18n.t('left')}:</label>
                            <input
                              type="number"
                              id="left"
                              className="form-control"
                              onChange={onInputChange}
                              value={binary.left}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <div className="form-group">
                            <label htmlFor="right">{i18n.t('right')}:</label>
                            <input
                              type="number"
                              id="right"
                              className="form-control"
                              onChange={onInputChange}
                              value={binary.right}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <div className="form-group">
                            <label>{i18n.t('save')}:</label>
                            <br />
                            <button className="btn btn-primary" onClick={onSubmit}>
                              {i18n.t('save')}
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <pre>{JSON.stringify(binary, null, 2)}</pre> */}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserBinaryModal
