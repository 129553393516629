import { encrypt } from '../utils/crypto'
import axios from './BaseService'

const CALLS_URL = `${process.env.REACT_APP_API_URL}/calls/`

export async function getCalls(token, page, limit, search) {
  const endPoint = `${CALLS_URL}list${page ? `?page=${page}` : ''}`
  const post = { limit, search }
  const signature = encrypt(JSON.stringify(post))
  const response = await axios.post(endPoint, post, { headers: { signature } })
  return response.data
}

export const getCallMessages = async callId => {
  const endPoint = `${CALLS_URL}call/${callId}`
  const response = await axios.get(endPoint)
  return response.data
}

export const sendCallMessages = async post => {
  const endPoint = `${CALLS_URL}reply`
  const signature = encrypt(JSON.stringify(post))
  const response = await axios.post(endPoint, post, { headers: { signature } })
  return response.data
}
