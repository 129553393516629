import { encrypt } from '../utils/crypto'
import axios from './BaseService'

const ENDPOINT = `${process.env.REACT_APP_API_URL}/binaries`

export async function getPercentageBinaries(token) {
  const endPoint = `${ENDPOINT}/percentages`
  const response = await axios.get(endPoint)
  return response.data
}

export async function savePercentages(token, post) {
  const endPoint = `${ENDPOINT}/savepercentages`
  const signature = encrypt(JSON.stringify(post))
  const response = await axios.put(endPoint, post, { headers: { signature } })
  return response.data
}
