/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import Pagination from '../../utils/pagination/Pagination'
import { i18n } from '../../utils/translates/i18n'
import LoaderPage from '../../utils/LoaderPage'
import { getRaffels, addRaffel } from '../../services/RaffelsService'
import { toast } from 'react-toastify'
import { Button, Modal } from 'react-bootstrap'
import { FormateDate } from '../../utils/CustomFormatations'

export default function Raffels() {
  const DEFAULT_DATA = {
    lottery: '',
    qtdDigits: 4,
    maxCards: 10000,
    price: 1,
    prize: '',
    drawDate: '',
    drawHours: 0,
    drawMinutes: 0,
    drawDue: '',
    dueHours: 0,
    dueMinutes: 0,
  }

  const defaultLocation = useLocation()
  const history = useHistory()

  const [show, setShow] = useState(false)
  const [raffels, setRaffels] = useState([])
  const [newRaffel, setNewRaffel] = useState(DEFAULT_DATA)
  const [modalAddRaffel, setModalAddRaffel] = useState(false)

  const [cardsView, setCardsView] = useState([])
  const [modalView, setModalView] = useState(false)

  // PARA PAGINAÇÃO
  const [search, setSearch] = useState('')
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [lim, setLims] = useState(10)
  const [ordenation, setOrdenation] = useState('desc')
  const [status, setStatus] = useState('')

  const doGetRaffels = async () => {
    const success = await getRaffels(search, page, lim, status, ordenation)
    if (success.status) {
      setRaffels(success.list.rows)
      setCount(success.list.count)
      setShow(true)
    } else {
      setShow(true)
    }
  }

  useEffect(() => {
    doGetRaffels()
  }, [page, lim, status, ordenation])

  function getPage(location) {
    if (!location) location = defaultLocation
    return new URLSearchParams(location.search).get('page')
  }

  useEffect(() => {
    return history.listen(location => {
      setPage(getPage(location))
    })
  }, [history])
  // FIM PARA PAGINAÇÃO

  const openModalAdd = data => {
    setNewRaffel(DEFAULT_DATA)
    if (data?.lottery) setNewRaffel(data)
    setModalAddRaffel(true)
    console.log(newRaffel)
  }

  const openModalView = data => {
    setCardsView(data)
    setModalView(true)
  }

  const doAddRaffle = async () => {
    setShow(false)
    if (
      !newRaffel.lottery ||
      !newRaffel.qtdDigits ||
      !newRaffel.maxCards ||
      !newRaffel.price ||
      !newRaffel.prize ||
      !newRaffel.drawDate ||
      !newRaffel.drawDue
    ) {
      setShow(true)
      toast.error(i18n.t('required_data_not_sent'))
      return
    } else {
      const success = await addRaffel(newRaffel)
      if (success.status) {
        setModalAddRaffel(false)
        toast.success(i18n.t(success.msg))
        doGetRaffels()
      } else {
        setShow(true)
        toast.error(i18n.t(success.msg))
      }
    }
  }

  return (
    <React.Fragment>
      <main className="content">
        {!show ? (
          <LoaderPage />
        ) : (
          <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <h2 className="h4">{i18n.t('raffels')}</h2>
              </div>
            </div>
            <Pagination count={count} pageSize={lim}>
              <div className="row w-100">
                <div className="col-lg-3 mb-3">
                  <button className="btn btn-primary w-100" onClick={openModalAdd}>{`${i18n.t('new_raffle')}`}</button>
                </div>
                <div className="col-lg-3 mb-3">
                  <div className="input-group me-2">
                    <select className="form-select" value={status} onChange={e => setStatus(e.target.value)}>
                      <option value="">{i18n.t('status')}</option>
                      <option value="1">{i18n.t('waiting')}</option>
                      <option value="4">{i18n.t('confirmed')}</option>
                      <option value="6">{i18n.t('canceled')}</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-3 mb-3">
                  <select className="form-select me-2" value={ordenation} onChange={e => setOrdenation(e.target.value)}>
                    <option className="bg-light" value="asc">
                      {i18n.t('olds')}
                    </option>
                    <option className="bg-light" value="desc">
                      {i18n.t('recents')}
                    </option>
                  </select>
                </div>
                <div className="col-lg-3 mb-3">
                  <select className="form-select me-2" value={lim} onChange={e => setLims(e.target.value)}>
                    <option className="bg-light" value="5">
                      5 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="10">
                      10 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="25">
                      25 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="50">
                      50 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="100">
                      100 {i18n.t('registers')}
                    </option>
                  </select>
                </div>
              </div>
            </Pagination>
            <div className="card card-body border-0 shadow table-wrapper table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th className="border-gray-200">{i18n.t('id')}</th>
                    <th className="border-gray-200">{i18n.t('lottery')}</th>
                    <th className="border-gray-200">{i18n.t('draw_date')}</th>
                    <th className="border-gray-200">{i18n.t('prize')}</th>
                    <th className="border-gray-200">{i18n.t('result')}</th>
                    <th className="border-gray-200">{i18n.t('winner')}</th>
                    <th className="border-gray-200">{i18n.t('action')}</th>
                  </tr>
                </thead>
                <tbody>
                  {raffels.map((r, i) => (
                    <tr key={i}>
                      <td>{r.id}</td>
                      <td>{r.lottery}</td>
                      <td>{FormateDate(r.drawDate, true, true)}</td>
                      <td>{r.prize}</td>
                      <td>{r.result}</td>
                      <td>{r.winner}</td>
                      <td>
                        <button className="btn btn-sm btn-primary" onClick={() => openModalView(r.cards)}>
                          <i className="fa fa-eye me-2"></i>
                          {i18n.t('cards')}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </main>
      {modalAddRaffel && (
        <Modal show={modalAddRaffel} onHide={() => setModalAddRaffel(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{i18n.t('new_raffle')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6 mb-3">
                <div className="form-group">
                  <label>{i18n.t('lottery')}:</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={e => setNewRaffel(p => ({ ...p, lottery: e.target.value }))}
                    value={newRaffel?.lottery}
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="form-group">
                  <label>{i18n.t('maxCards')}:</label>
                  <input
                    type="number"
                    step={1}
                    className="form-control"
                    onChange={e => setNewRaffel(p => ({ ...p, maxCards: e.target.value < 1 ? 1 : e.target.value }))}
                    value={newRaffel?.maxCards}
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="form-group">
                  <label>{i18n.t('price')}:</label>
                  <input
                    type="number"
                    step={1}
                    className="form-control"
                    onChange={e => setNewRaffel(p => ({ ...p, price: e.target.value < 1 ? 1 : e.target.value }))}
                    value={newRaffel?.price}
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="form-group">
                  <label>{i18n.t('prize')}:</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={e => setNewRaffel(p => ({ ...p, prize: e.target.value }))}
                    value={newRaffel?.prize}
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="form-group">
                  <label>{i18n.t('draw_date')}:</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={e => setNewRaffel(p => ({ ...p, drawDate: e.target.value }))}
                    value={newRaffel?.drawDate}
                  />
                </div>
              </div>
              <div className="col-lg-3 mb-3">
                <div className="form-group mb-2">
                  <label>{i18n.t('hour')}:</label>
                  <input
                    type="number"
                    min={0}
                    max={23}
                    step={1}
                    value={newRaffel.drawHours}
                    className="form-control"
                    onChange={e => setNewRaffel(p => ({ ...p, drawHours: e.target.value }))}
                    onBlur={e =>
                      setNewRaffel(p => ({
                        ...p,
                        drawHours: e.target.value < 0 ? 0 : e.target.value > 23 ? 23 : e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="col-lg-3 mb-3">
                <div className="form-group mb-2">
                  <label>{i18n.t('minutes')}:</label>
                  <input
                    type="number"
                    min={0}
                    max={59}
                    step={1}
                    value={newRaffel.drawMinutes}
                    className="form-control"
                    onChange={e => setNewRaffel(p => ({ ...p, drawMinutes: e.target.value }))}
                    onBlur={e =>
                      setNewRaffel(p => ({
                        ...p,
                        drawMinutes: e.target.value < 0 ? 0 : e.target.value > 59 ? 59 : e.target.value,
                      }))
                    }
                  />
                </div>
              </div>

              <div className="col-lg-6 mb-3">
                <div className="form-group">
                  <label>{i18n.t('draw_due')}:</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={e => setNewRaffel(p => ({ ...p, drawDue: e.target.value }))}
                    value={newRaffel?.drawDue}
                  />
                </div>
              </div>
              <div className="col-lg-3 mb-3">
                <div className="form-group mb-2">
                  <label>{i18n.t('hour')}:</label>
                  <input
                    type="number"
                    min={0}
                    max={23}
                    step={1}
                    value={newRaffel.dueHours}
                    className="form-control"
                    onChange={e => setNewRaffel(p => ({ ...p, dueHours: e.target.value }))}
                    onBlur={e =>
                      setNewRaffel(p => ({
                        ...p,
                        dueHours: e.target.value < 0 ? 0 : e.target.value > 23 ? 23 : e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="col-lg-3 mb-3">
                <div className="form-group mb-2">
                  <label>{i18n.t('minutes')}:</label>
                  <input
                    type="number"
                    min={0}
                    max={59}
                    step={1}
                    value={newRaffel.dueMinutes}
                    className="form-control"
                    onChange={e => setNewRaffel(p => ({ ...p, dueMinutes: e.target.value }))}
                    onBlur={e =>
                      setNewRaffel(p => ({
                        ...p,
                        dueMinutes: e.target.value < 0 ? 0 : e.target.value > 59 ? 59 : e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
            {/* <pre>{JSON.stringify(newRaffel, null, 2)}</pre> */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalAddRaffel(false)}>
              {i18n.t('cancel')}
            </Button>
            <Button variant="primary" onClick={doAddRaffle}>
              {i18n.t('save')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {modalView && (
        <Modal show={modalView} onHide={() => setModalView(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{i18n.t('tickets')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <pre>{JSON.stringify(cardsView, null, 2)}</pre>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  )
}
