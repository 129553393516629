import React, { useEffect, useState } from 'react'

// import Footer from '../../components/Footer/Footer'
import { i18n } from '../../utils/translates/i18n'
import Toast from '../../components/Toast/Toast'
import { getActiveUsers } from '../../services/UsersService'
import { sendCredDebt } from '../../services/CredDebtService'
import LoaderPage from '../../utils/LoaderPage'

function CredDeb() {
  const token = sessionStorage.getItem('token')
  const [notification, setNotification] = useState({ text: '', type: '' })
  const [users, setUsers] = useState([])
  const [dataPost, setDataPost] = useState({ user: '', type: '', amount: 0 })
  const [show, setShow] = useState(false)

  const doGetUsers = async token => {
    const success = await getActiveUsers(token)
    if (success.length > 0) setUsers(success)
    setShow(true)
  }

  const onInputChange = e => setDataPost(p => ({ ...p, [e.target.id]: e.target.value }))

  const doSave = async () => {
    setShow(false)
    const post = {
      ...dataPost,
      user: dataPost.user.split(' | ')[0],
    }
    if (!!parseInt(post.user)) {
      const success = await sendCredDebt(token, post)
      if (success.status)
        setNotification({
          text: `Credito / Débito enviado com sucesso para ${dataPost.user.split(' | ')[1]}`,
          type: 'success',
        })
      setDataPost({ user: '', type: '', amount: 0 })
      setShow(true)
    } else {
      setShow(true)
      setNotification({ text: 'Escolha um usuário na lista', type: 'error' })
    }
  }

  useEffect(() => {
    doGetUsers(token)
  }, [token])

  const t = sessionStorage.getItem('t') === 'true'
  return (
    !t && (
      <React.Fragment>
        <main className="content">
          {!show ? (
            <LoaderPage />
          ) : (
            <>
              <div className="container-fluid">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                  <div className="d-block mb-4 mb-md-0">
                    <div className="h3">{i18n.t('cred_deb')}</div>
                  </div>
                </div>
                <div className={`alert alert-${notification.type === 'error' ? 'danger' : notification.type}`}>
                  {notification.text}
                </div>
                <div className="card card-body border-0 shadow mb-5">
                  <div className="h5">{i18n.t('cred_deb_title')}</div>
                  <div className="row">
                    <div className="col-md">
                      <div className="input-group">
                        <span className="btn btn-primary">{i18n.t('user')}</span>
                        <input
                          id="user"
                          list="userlist"
                          value={dataPost.user}
                          onChange={onInputChange}
                          className="form-control"
                          placeholder="digite e escolha"
                        />
                        <datalist id="userlist">
                          {users && users.map((u, i) => <option key={i} value={`${u.id} | ${u.nick} - ${u.name}`} />)}
                        </datalist>
                      </div>
                    </div>
                    {/* <div className="col-md">
                    <div className="input-group">
                      <span className="btn btn-primary">{i18n.t('user')}</span>
                      <select id="user" value={dataPost.user} onChange={onInputChange} className="form-select">
                        <option value="">-- {i18n.t('choose')} --</option>
                        {users && users.map((u, i) => <option key={i} value={u.id}>{`${u.nick} - ${u.name}`}</option>)}
                      </select>
                    </div>
                  </div> */}
                    <div className="col-md">
                      <div className="input-group">
                        <span className="btn btn-primary">{i18n.t('type')}</span>
                        <select id="type" value={dataPost.type} onChange={onInputChange} className="form-select">
                          <option value="">-- {i18n.t('choose')} --</option>
                          <option value="12">{i18n.t('cred_saldo_disp')}</option>
                          <option value="16">{i18n.t('deb_saldo_disp')}</option>
                          {/* <option value="17">{`${i18n.t('pay')} ${i18n.t('pl_carreira')}`}</option> */}
                          {/* <option value="14">{i18n.t('ajust_limite')}</option> */}
                          {/* <option value="9">{i18n.t('extorno_td')}</option> */}
                        </select>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="input-group">
                        <span className="btn btn-primary">{i18n.t('amount')}</span>
                        <input
                          id="amount"
                          value={dataPost.amount}
                          type="number"
                          onChange={onInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md">
                      <button className="btn btn-success text-white" onClick={doSave}>
                        <i className="fa fa-check"></i>
                        <span className="ms-2">{i18n.t('send')}</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* <pre>{JSON.stringify(dataPost, null, 2)}</pre> */}
              </div>
            </>
          )}
        </main>
        {/* <Footer /> */}
        <Toast type={notification.type} text={notification.text} />
      </React.Fragment>
    )
  )
}

export default CredDeb
