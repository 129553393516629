import { encrypt } from '../utils/crypto'
import axios from './BaseService'

const ENDPOINT = `${process.env.REACT_APP_API_URL}`

export async function sendCredDebt(token, post) {
  const endPoint = `${ENDPOINT}/creddebt`
  const signature = encrypt(JSON.stringify(post))
  const response = await axios.post(endPoint, post, { headers: { signature } })
  return response.data
}

export async function sendProlabore(post) {
  const endPoint = `${ENDPOINT}/prolabore`
  const signature = encrypt(JSON.stringify(post))
  const response = await axios.post(endPoint, post, { headers: { signature } })
  return response.data
}
