/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import Pagination from '../../utils/pagination/Pagination'
import { getInfluencers } from '../../services/InfluencersService.js'
import { i18n } from '../../utils/translates/i18n'
import InfluencerRow from './InfluencerRow.js'
import InfluencerModal from './InfluencerModal/InfluencerModal.js'

function Influencers() {
  const defaultLocation = useLocation()
  const history = useHistory()

  const [influencers, setInfluencers] = useState([])
  const [editInfluencer, setEditInfluencer] = useState(null)

  // PARA PAGINAÇÃO
  const token = sessionStorage.getItem('token')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('')
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [lim, setLims] = useState(10)

  const doGetInfluencers = async () => {
    const success = await getInfluencers(token, search, status, lim, page)
    if (success.status) {
      setInfluencers(success.list.rows)
      setCount(success.list.count)
    }
  }

  useEffect(() => {
    doGetInfluencers()
  }, [token, page, lim, search, status])

  function getPage(location) {
    if (!location) location = defaultLocation
    return new URLSearchParams(location.search).get('page')
  }

  useEffect(() => {
    return history.listen(location => {
      setPage(getPage(location))
    })
  }, [history])
  // FIM PARA PAGINAÇÃO

  const doEditInfluencer = influencer => {
    if (!influencer) return
    setEditInfluencer(influencer)
  }

  return (
    <React.Fragment>
      <main className="content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
              <h2 className="h4">{i18n.t('influencers')}</h2>
            </div>
          </div>
          <Pagination count={count} pageSize={lim}>
            <div className="align-items-center">
              <div className="alert bg-dark m-0 py-2 d-flex justify-content-around align-items-center">
                <i className="fas fa-search"></i>
              </div>
            </div>
            <button
              className="btn btn-primary btn-sm mx-2"
              data-bs-toggle="modal"
              data-bs-target="#modalInfluencer"
              onClick={() => doEditInfluencer({ at: '@' })}>
              <span className="d-flex flex-row gap-2 align-items-center justify-content-center">
                <i className="fa fa-plus"></i> {i18n.t('new')}
              </span>
            </button>
            <div className="input-group mx-3">
              <input
                type="text"
                className="form-control"
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder={`${i18n.t('search')}`}
              />
            </div>
            <div className="input-group mx-3">
              <select className="form-select" value={status} onChange={e => setStatus(e.target.value)}>
                <option value="">{i18n.t('status')}</option>
                <option value="1">{i18n.t('waiting')}</option>
                <option value="4">{i18n.t('confirmed')}</option>
                <option value="5">{i18n.t('suspended')}</option>
                <option value="8">{i18n.t('revoked')}</option>
                <option value="10">{i18n.t('banned')}</option>
                <option value="18">{i18n.t('awarded')}</option>
              </select>
            </div>
            <select className="form-select w-50" value={lim} onChange={e => setLims(e.target.value)}>
              <option className="bg-light" value="5">
                5 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="10">
                10 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="25">
                25 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="50">
                50 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="100">
                100 {i18n.t('registers')}
              </option>
            </select>
          </Pagination>
          <div className="card card-body border-0 shadow table-wrapper table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="border-gray-200">{i18n.t('img')}</th>
                  <th className="border-gray-200">{i18n.t('name')}</th>
                  <th className="border-gray-200">{i18n.t('nick')}</th>
                  <th className="border-gray-200">{i18n.t('instagram')}</th>
                  <th className="border-gray-200">{i18n.t('followers')}</th>
                  <th className="border-gray-200">{i18n.t('status')}</th>
                  <th className="border-gray-200">{i18n.t('action')}</th>
                </tr>
              </thead>
              <tbody>
                {influencers?.map((influencer, i) => (
                  <InfluencerRow influencer={influencer} key={i} editInfluencer={doEditInfluencer} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </main>
      {/* <Footer /> */}
      <InfluencerModal influencer={editInfluencer} onSubmit={doGetInfluencers} />
      {/* <Toast type={notification.type} text={notification.text} /> */}
    </React.Fragment>
  )
}

export default Influencers
